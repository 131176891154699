<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="noPower" v-if="noPower !== true">
          <span>
            <i class="el-icon-lock"></i>
          </span>
        </div>
        <div class="content-left">
          <div class="table-content">
            <el-row :gutter="60">
              <el-col
                :xs="24"
                :sm="12"
                :md="8"
                :lg="4"
                :xl="4"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="ladderItem"
                  @click="clickLadder(item)"
                >
                  <div class="img">
                    <el-image
                      fit="cover"
                      v-if="item.cover"
                      style="width: 100%; height: 100%;border-radius: 8px 8px 0 0;"
                      :src="item.cover"
                      alt=""
                    />
                    <el-image
                      fit="cover"
                      style="width: 100%; height: 100%;border-radius: 8px 8px 0 0;"
                      v-else
                      src="../../assets/index/fb.webp"
                      alt=""
                    />
                    <div class="mb" v-show="item.pass_speed <= 0">
                      <i class="el-icon-lock"></i>
                    </div>
                    <div
                      class="tags"
                      style="position: absolute; top: 0; left: 0"
                    >
                      <div class="tag free" v-if="item.is_pass !=0">已通关</div>
                      <div class="tag wait" v-if="item.is_pass == 0 && item.pass_speed==1 && item.is_answer==0">待通关</div>
                      <div class="tag pay" v-else-if="item.is_pass == 0&&item.pass_speed==1&& item.is_answer==1">通关中</div>
                    </div>
                  </div>
                  <div class="bottomInfo">
                    <div class="name">{{ item.title }}</div>
                    <div style="display: flex;justify-content: space-between;margin-top: 6px;">
                      <div>题数:{{ item.question_num }}</div>
                      <div>满分:{{item.total_grade}}分</div>
                    </div>
                    <div style="margin: 6px 0">
                      通关最低分:{{item.pass_grade}}分
                    </div>
                    <div>
                    <!-- <div > -->
                      通关方式:<span :class="item.type==0?'': item.type==1?'normalpass':'fastpass'">{{item.type==0?'-': item.type==1?'普通通关':'快速通道通关'}}</span>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                      <div v-if="item.type==2" style="margin-top: 6px;">
                      <!-- <div style="margin-top: 6px;"> -->
                        耗时:<span>{{ item.duration?item.duration:'-'}}</span>
                      </div>
                      <div :class="item.type==0?'': item.type==1?'normalpass':'fastpass'" v-if="item.user_grade">
                        得分:<span style="font-size: 16px;font-weight: bold;">{{ item.user_grade}}分</span>
                      </div>
                      <div :class="item.type==0?'': item.type==1?'normalpass':'fastpass'" v-else>
                        得分:<span style="font-size: 16px;font-weight: bold;">--</span>
                      </div>
                    </div>
                    
                    <div style="margin-top: 6px">
                      通关时间:{{ item.pass_time||'--' }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="currentChange"
              :page-size="pageSize"
              :current-page="page"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ladderList } from "@/api/ladder.js";

export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 32,
      total: 0,
      loading: false,
      tableData: [],
      noPower: false,
    };
  },
  mounted() {
    this.getTeamList();
  },
  methods: {
    // 点击官咖
    clickLadder(v, i) {
      // 0不通过 1通过
      if (v.pass_speed <= 0) {
        return this.$message.info("还未解锁");
      } else {
        this.$router.push({
          path: "/ladder/stage",
          query: { id: v.id, name: v.title },
        });
      }
    },
    // 关卡列表
    getTeamList() {
      this.loading = true;
      ladderList({
        page: this.page,
        page_size: this.pageSize,
      })
        .then(({ data }) => {
          this.loading = false;
          this.tableData = data.data;
          this.total = data.total;
          this.noPower = data.noPower;
          console.log(data, "团队列表成功");
        })
        .catch((err) => {
          this.loading = false;

          console.log(err, "团队列表失败");
        });
    },

    // 分页
    currentChange(e) {
      this.page = e;
      this.getTeamList();
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 1200px) {
    .el-col-lg-4 {
        width: 16.66667%;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
.pagination {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.box {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 260px;
  .noPower {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffffa3;
    z-index: 99;
    text-align: center;
    font-size: 30px;
    color: #3e3e3e;
    padding-top: 100px;
    span {
      .el-icon-lock {
        font-weight: 800;
        font-size: 60px;
        color: #fc9567;
      }
    }
  }
  .content-left {
    width: 100%;

    .table-content {
      min-height: 215px;
      padding: 1rem;
      box-sizing: border-box;
      background-color: #fff;
      .ladderItem {
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;
        .img {
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
          .mb {
            position: absolute;
            top: 0;
            text-align: center;
            line-height: 135px;
            width: 100%;
            height: 100%;
            // height: 140px;
            background: rgba(0, 0, 0, 0.2);
            i {
              color: #000;
              font-size: 24px;
              font-weight: 700;
            }
          }
          .tags {
            width: 46px;
            height: 22px;
            .tag {
              border-radius: 8px 0px 8px 0px;

              width: 46px;
              height: 22px;
              line-height: 22px;
              text-align: center;
              font-size: 12px;
              color: #ffffff;
            }
            .free {
              background: #e98000;
            }
            .pay {
              background-color: #008992;
            }
            .wait{
              background-color: #F56C6C;

            }
          }
        }
        .bottomInfo {
          box-shadow: 0px 10px 14px 0px rgba(198, 198, 198, 0.34);
          padding: 10px;
          font-size: 0.8rem;
          color:#9199A1 ;
          .name {
          font-size: 0.8rem;
            font-weight: bold;
            // text-align: center;
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap; // 默认不换行；
          }
          .normalpass{
            color: #e98000;
          }
          .fastpass{
            color:#00957E;
          }
        }
      }
    }
  }
}
</style>
