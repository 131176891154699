import request from "@/Utils/api";
// 分类列表
export function ladderList(data){
    return request({
        url: "/api/v2_0_0.high_ladder/list",
        method: "post",
        cache:true,
        data,
      });
}
// 天梯计划 - 关卡详情列表
export function checkPointList(data){
  return request({
      url: "/api/v2_0_0.high_ladder/checkPoint",
      method: "post",
      data,
    });
}
// 天梯计划 - 快速通道
export function kstdFast(data){
  return request({
      url: "/api/v2_0_0.high_ladder/fast",
      method: "post",
      cache:true,
      data,
    });
}
// 天梯计划 - 快速通道 提交答题
export function kstdAnswer(data){
  return request({
      url: "/api/v2_0_0.high_ladder/answer",
      method: "post",
      data,
    });
}
// 天梯计划 -  单题 -提交答题
export function dtDanswer(data){
  return request({
      url: "/api/v2_0_0.high_ladder/danswer",
      method: "post",
      data,
    });
}


// 课程获取题单
// export function getListQuest(data){
//   return request({
//       url: "/api/v2_0_0.course/getListQuest",
//       method: "post",
//       cache:true,
//       data,
//     });
// }
export function getListQuest(data){
  return request({
      url: "/api/v1_0_0.question_other/detail",
      method: "post",
      data,
    });
}

// 课程答题
// export function kcAnswer(data){
//   return request({
//       url: "/api/v2_0_0.course/answer",
//       method: "post",
//       cache:true,
//       data,
//     });
// }
export function kcAnswer(data){
  return request({
      url: "/api/v1_0_0.question_other/answer",
      method: "post",
      data,
    });
}


// 课程 - 课程进度
export function speed(data){
  return request({
      url: "/api/v2_0_0.course/speed",
      method: "post",
      cache:true,
      data,
    });
}
// 课程 - 课程评语
export function classEvaluate(data){
  return request({
      url: "/api/v2_0_0.course/evaluate",
      method: "post",
      cache:true,
      data,
    });
}
// 判断条目是否有权限
export function jurisdiction(data){
  return request({
      url: "/api/v2_0_0.course/info_list",
      method: "post",
      data,
    });
}
// 通过记录
export function timePasslog(data){
  return request({
      url: "/api/v2_0_0.course/passlog",
      method: "post",
      cache:false,
      message:false,
      data,
    });
}









